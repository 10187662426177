import React from "react"
import {
  CarouselSlideRenderControlProps,
  CarouselCellAlignProp,
} from "nuka-carousel"
import cx from "classnames"

import classes from "./slider-nav.module.css"
import SliderNextImage from "../../images/icons/slider-next.inline.svg"
import ImagesIcon from "../../images/icons/image.inline.svg"

const getDotIndexes = (
  slideCount: number,
  slidesToScroll: number,
  slidesToShow: number,
  cellAlign: CarouselCellAlignProp
) => {
  const dotIndexes: number[] = []
  let lastDotIndex: number = slideCount - slidesToShow

  switch (cellAlign) {
    case "center":
    case "right":
      lastDotIndex += slidesToShow - 1
      break
  }
  if (lastDotIndex < 0) {
    return [0]
  }

  for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
    dotIndexes.push(i)
  }
  dotIndexes.push(lastDotIndex)
  return dotIndexes
}

export const SliderNav: React.FunctionComponent<
  {
    position: "left" | "center"
    className: string
  } & CarouselSlideRenderControlProps
> = ({
  slideCount,
  slidesToScroll,
  slidesToShow,
  cellAlign,
  currentSlide,
  goToSlide,
  position,
  className,
}) => {
  const indexes = getDotIndexes(
    slideCount,
    slidesToScroll,
    slidesToShow,
    cellAlign
  )
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    newPage: number
  ) => {
    e.preventDefault()
    goToSlide(newPage)
  }
  return (
    <nav
      className={cx(classes.slider__nav, className)}
      data-position={position}
    >
      <ul>
        {indexes.map(index => {
          return (
            <li
              key={index}
              className={cx(
                index === currentSlide && classes.slider__nav__current
              )}
            >
              <a href="#" onClick={e => handleClick(e, index)}>
                {index}
              </a>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export const SliderNext: React.FunctionComponent<
  { className: string } & CarouselSlideRenderControlProps
> = ({ nextSlide, className }) => {
  return (
    <div className={cx(classes.slider__next, className)}>
      <button onClick={nextSlide}>
        <SliderNextImage />
      </button>
    </div>
  )
}

export const SliderPrev: React.FunctionComponent<
  { className: string } & CarouselSlideRenderControlProps
> = ({ previousSlide, className }) => {
  return (
    <div className={cx(classes.slider__next, classes.slider__prev, className)}>
      <button onClick={previousSlide}>
        <SliderNextImage />
      </button>
    </div>
  )
}

export const SliderCount: React.FunctionComponent<
  CarouselSlideRenderControlProps
> = ({ slideCount, currentSlide }) => (
  <div className={classes.slider__count}>
    <ImagesIcon />
    <span>
      {currentSlide + 1}/{slideCount}
    </span>
  </div>
)
