import cx from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FixedObject } from "gatsby-image"
import Carousel from "nuka-carousel"
import React, { useState } from "react"
import { animated, useTransition } from "react-spring"
import classes from "../components/agents/agents.module.css"
import { SliderNav, SliderNext } from "../components/agents/slider-nav"
import { AppStoreButton, Button, LinkButton } from "../components/button"
import HeroContainer, {
  HeroBackground,
  HeroContent,
} from "../components/containers/hero"
import SEO from "../components/seo"
import heroLogo from "../images/reddoor-hero-logo-v3.svg"

interface SectionFeature {
  title: string
  description: string
  image: FixedObject
  imagePosition: "right" | "left"
  button: string
}

interface QuoteSlide {
  quote: string
  name: string
  yearsActive: string
  location: string
  image: FixedObject
  slideBackground: HeroBackground
}

const AgentsPage = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  const images = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "screen_hero_1@2x.png" }) {
        childImageSharp {
          fixed(width: 226) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      screen2: file(relativePath: { eq: "screen_hero_2@2x.png" }) {
        childImageSharp {
          fixed(width: 226) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      screen3: file(relativePath: { eq: "screen_hero_3@2x.png" }) {
        childImageSharp {
          fixed(width: 226) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      screen4: file(relativePath: { eq: "screen_hero_4@2x.png" }) {
        childImageSharp {
          fixed(width: 226) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      randallsAvatar: file(relativePath: { eq: "faces/randall@3x.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      michaelsAvatar: file(relativePath: { eq: "faces/michael@3x.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      donsAvatar: file(relativePath: { eq: "faces/don@3x.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      brand1: file(relativePath: { eq: "hero_brand_1@2x.jpg" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      brand2: file(relativePath: { eq: "hero_brand_2@2x.jpg" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      brand3: file(relativePath: { eq: "hero_brand_3@2x.jpg" }) {
        childImageSharp {
          fixed(height: 49) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      brand4: file(relativePath: { eq: "hero_brand_4@2x.jpg" }) {
        childImageSharp {
          fixed(height: 42) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      brand5: file(relativePath: { eq: "hero_brand_5@2x.jpg" }) {
        childImageSharp {
          fixed(height: 42) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      brand6: file(relativePath: { eq: "hero_brand_6@2x.jpg" }) {
        childImageSharp {
          fixed(height: 35) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const features: SectionFeature[] = [
    {
      title: "Instant Insight",
      description: "See when  your clients take action, and stay in the loop.",
      image: images.screen1.childImageSharp.fixed,
      imagePosition: "right",
      button: "Try it",
    },
    {
      title: "Business Building Blocks",
      description:
        "Get new clients connected to you effortlessly. Be their HERO. ",
      image: images.screen2.childImageSharp.fixed,
      imagePosition: "left",
      button: "See options",
    },
    {
      title: "Safe And Accurate",
      description:
        "Built for the way we work today, HERO is safe, secure, and accurate.",
      image: images.screen3.childImageSharp.fixed,
      imagePosition: "right",
      button: "Try it",
    },
    {
      title: "Dedicated Loan Officers",
      description:
        "Our loan officers don’t work on commission. They work to give you and your client the best experience.",
      image: images.screen4.childImageSharp.fixed,
      imagePosition: "left",
      button: "Get started",
    },
  ]

  const brands = [
    {
      image: images.brand1.childImageSharp.fixed,
      title: "Re/Max",
    },
    {
      image: images.brand2.childImageSharp.fixed,
      title: "Exp Realty",
    },
    {
      image: images.brand3.childImageSharp.fixed,
      title: "Better Homes",
    },
    {
      image: images.brand4.childImageSharp.fixed,
      title: "Sothebey’s International Realty",
    },
    {
      image: images.brand5.childImageSharp.fixed,
      title: "KellerWilliams",
    },
    {
      image: images.brand6.childImageSharp.fixed,
      title: "Coldwell Banker Select",
    },
  ]

  const slides: QuoteSlide[] = [
    {
      quote: `12 days. That's how long it took for my first transaction to close through RedDoor. It was fast, my client got a great deal, and anytime I had a question their loan officer got back to me immediately.`,
      name: "Randall",
      yearsActive: "10+",
      location: "Sacramento",
      image: images.randallsAvatar.childImageSharp.fixed,
      slideBackground: HeroBackground.DarkPurple,
    },
    {
      quote: `It used to take multiple emails and calls just to find out where a client transaction was in the process, but now I open the app and it's right there. I'd call this a game changer but honestly, it's a whole new game.`,
      name: "Michael",
      yearsActive: "15+",
      location: "Marin",
      image: images.michaelsAvatar.childImageSharp.fixed,
      slideBackground: HeroBackground.DarkPurple,
    },
    {
      quote: `I was skeptical so I tried the app at an open house, sent an invite link to a couple that was casually looking, they opened it right there, saw their buying power and loan options...and called me the next day to put in an offer!`,
      name: "Don",
      yearsActive: "25+",
      location: "Sacramento",
      image: images.donsAvatar.childImageSharp.fixed,
      slideBackground: HeroBackground.DarkPurple,
    },
  ]

  const transitions = useTransition(slideIndex, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true,
  })

  return (
    <>
      <SEO title="Agents" />
      <HeroContainer
        className={classes.heroCover}
        variant="medium-dark"
        lastContainer
        noContainer
      >
        <section
          className={cx(classes.heroContainer, classes.heroContainerTitle)}
        >
          <section className={classes.heroContent}>
            <img
              src={heroLogo}
              alt="RedDoor Hero"
              className={classes.heroLogo}
            />
            <h2>
              We Gave Agents
              <br />
              Super Powers
            </h2>
            <h4>
              Grow your business and close more deals, while providing even
              deeper value to your clients while staying connected all along the
              way.
            </h4>
            <div className={classes.heroButtons}>
              <AppStoreButton
                heroApp
                isHero
                text="GET HERO"
                className={cx(classes.button, classes.heroButton)}
              />
              <LinkButton
                className={classes.button}
                isHero
                href="https://calendly.com/rick-reddoor/hero-15-minute-product-demo"
                target="_blank"
              >
                15 Minute Demo
              </LinkButton>
            </div>
          </section>
        </section>
        <section
          className={cx(classes.heroContainer, classes.heroContainerQuotes)}
        >
          <div className={classes.sliderBackground}>
            {transitions.map(({ item, props, key }) => (
              <animated.div
                key={key}
                style={{
                  ...props,
                  background: `var(--${slides[item].slideBackground}-gradient)`,
                }}
              />
            ))}
          </div>
          <section className={classes.heroContent}>
            <Carousel
              dragging={false}
              swiping={false}
              wrapAround
              cellSpacing={10}
              slideIndex={slideIndex}
              renderCenterLeftControls={() => null}
              renderBottomCenterControls={props => (
                <SliderNav
                  className={classes.slider__nav}
                  position="center"
                  {...props}
                />
              )}
              renderBottomLeftControls={props => (
                <SliderNav
                  className={classes.slider__nav}
                  position="left"
                  {...props}
                />
              )}
              renderCenterRightControls={props => (
                <SliderNext className={classes.slider__next} {...props} />
              )}
              beforeSlide={(_, index) => setSlideIndex(index)}
            >
              {slides.map((slide, i) => (
                <section
                  className={classes.slide}
                  key={i}
                  onClick={() =>
                    setSlideIndex((slideIndex + 1) % slides.length)
                  }
                >
                  <blockquote>
                    <p>{slide.quote}</p>
                    <cite>
                      <div className={classes.slide__agent__avatar}>
                        <Img
                          fixed={slide.image}
                          className={classes.slide__agent__avatarImage}
                        />
                      </div>
                      <div className={classes.slide__agent}>
                        <h4>{slide.name}</h4>
                        <p>Real estate agent</p>
                        <small>
                          {slide.yearsActive} years active • {slide.location}
                        </small>
                      </div>
                    </cite>
                  </blockquote>
                </section>
              ))}
            </Carousel>
          </section>
        </section>
      </HeroContainer>
      <section className={classes.featuresSection}>
        <section className={cx("container", classes.featuresContainer)}>
          {features.map((feature, i) => (
            <section
              key={i}
              className={cx(
                classes.feature,
                feature.imagePosition === "left" && classes.featureImageLeft
              )}
            >
              <div className={classes.feature__info}>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
                <AppStoreButton
                  text={feature.button}
                  heroApp
                  className={classes.button}
                />
              </div>
              <div className={classes.feature__image}>
                <Img fadeIn={false} fixed={feature.image} />
              </div>
            </section>
          ))}
        </section>
      </section>
      <HeroContainer autoSize>
        <HeroContent>
          <h2>Be Super, Powerful.</h2>
          <h4>Join agents from all the top brands who trust RedDoor.</h4>

          <section className={classes.brands__section}>
            <h3>Trusted By Top Agents And Brands.</h3>
            <section className={classes.brands}>
              {brands.map((brand, i) => (
                <Img fadeIn={false} fixed={brand.image} key={i} />
              ))}
            </section>
          </section>
          <div className={classes.heroButtons}>
            <AppStoreButton
              heroApp
              isHero
              text="GET HERO"
              className={cx(classes.button, classes.heroButton)}
            />
            <LinkButton
              className={classes.button}
              isHero
              href="https://calendly.com/rick-reddoor/hero-15-minute-product-demo"
              target="_blank"
            >
              15 Minute Demo
            </LinkButton>
          </div>
        </HeroContent>
      </HeroContainer>
    </>
  )
}

export default AgentsPage
